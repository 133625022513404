import flytebrygge from "../static/typeIcons/flytebrygge.svg";
import kai from "../static/typeIcons/kai.svg";
import fortoyningsbolter from "../static/typeIcons/fortoyningsbolter.svg";
import batutsettingsrampe from "../static/typeIcons/batutsettingsrampe.svg";
import fortoyningsboye from "../static/typeIcons/fortoyningsboye.svg";
import ankring from "../static/typeIcons/ankring.svg";
import badeplass from "../static/typeIcons/badeplass.svg";
import padling from "../static/typeIcons/padling.svg";
import fiske from "../static/typeIcons/fiske.svg";
import baalOgGrill from "../static/typeIcons/baalOgGrill.svg";
import lek from "../static/typeIcons/lek.svg";
import lekeplass from "../static/typeIcons/lekeplass.svg";
import klatring from "../static/typeIcons/klatrefelt.svg";
import fottur from "../static/typeIcons/fottur.svg";
import ferskvann from "../static/typeIcons/ferskvann.svg";
import basseng from "../static/typeIcons/basseng.svg";
import toalett from "../static/typeIcons/toalett.svg";
import gapahuk from "../static/typeIcons/gapahuk.svg";
import parkeringsplass from "../static/typeIcons/parkeringsplass.svg";
import friluftsomrade from "../static/typeIcons/friluftsomrade.svg";
import rasteplass from "../static/typeIcons/rasteplass.svg";
import utedo from "../static/typeIcons/utedo.svg";
import stupebrett from "../static/typeIcons/stupebrett.svg";
import utsettingKanoKajakk from "../static/typeIcons/utsettingKanoKajakk.svg";
import grill from "../static/typeIcons/grill.svg";
import aktivitetsanlegg from "../static/typeIcons/aktivitetsanlegg.svg";
import ballokke from "../static/typeIcons/ballokke.svg";
import hyttemer from "../static/typeIcons/hyttemer.svg";
import hytte from "../static/typeIcons/hytte.svg";
import teltplass from "../static/typeIcons/teltplass.svg";
import sykkeltur from "../static/typeIcons/sykling.svg";
import badeflaate from "../static/typeIcons/badeflaate.png";
import ladestasjon from "../static/typeIcons/ladestasjon.svg";
import { Style, Circle, Icon, Fill, Stroke } from "ol/style";

const featureStyle = (f) => {
  const symbol = f.get("symbol_id");
  const opacity = f.get("faded") ? 0.5 : 1;
  const datakilde = f.get("datakilde");
  const zIndex = 99;

  if (f.get("hover")) {
    return;
  }

  if (datakilde === 2) {
    return new Style({
      image: new Icon({
        src: friluftsomrade,
        anchor: [0.5, 1],
        opacity,
      }),
      zIndex,
    });
  }

  switch (symbol) {
    case 4:
      return new Style({
        image: new Icon({
          src: badeflaate,
          anchor: [0.5, 1],
          opacity,
          scale: 0.5,
        }),
        zIndex,
      });

    case 5:
      return new Style({
        image: new Icon({
          src: badeplass,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 6:
      return new Style({
        image: new Icon({
          src: badeplass,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 8:
      return new Style({
        image: new Icon({
          src: ballokke,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 9:
      return new Style({
        image: new Icon({
          src: basseng,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 13:
      return new Style({
        image: new Icon({
          src: baalOgGrill,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 14:
      return new Style({
        image: new Icon({
          src: batutsettingsrampe,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 17:
      return new Style({
        image: new Icon({
          src: fottur,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 18:
      return new Style({
        image: new Icon({
          src: utsettingKanoKajakk,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 20:
      return new Style({
        image: new Icon({
          src: ferskvann,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 21:
      return new Style({
        image: new Icon({
          src: fiske,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 22:
      return new Style({
        image: new Icon({
          src: flytebrygge,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 24:
      return new Style({
        image: new Icon({
          src: fortoyningsbolter,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 25:
      return new Style({
        image: new Icon({
          src: fortoyningsboye,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 29:
      return new Style({
        image: new Icon({
          src: gapahuk,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 33:
      return new Style({
        image: new Icon({
          src: grill,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 37:
      return new Style({
        image: new Icon({
          src: aktivitetsanlegg,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 40:
      return new Style({
        image: new Icon({
          src: kai,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 42:
      return new Style({
        image: new Icon({
          src: klatring,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 47:
      return new Style({
        image: new Icon({
          src: lek,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 48:
      return new Style({
        image: new Icon({
          src: lekeplass,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 54:
      return new Style({
        image: new Icon({
          src: ladestasjon,
          anchor: [0.5, 1],
          opacity,
          //backgroundFill: new Fill({ color: "#7DDB98" }),
        }),
        zIndex,
      });

    case 55:
      return new Style({
        image: new Icon({
          src: parkeringsplass,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 56:
      return new Style({
        image: new Icon({
          src: rasteplass,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 66:
      return new Style({
        image: new Icon({
          src: stupebrett,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 67:
      return new Style({
        image: new Icon({
          src: teltplass,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 68: // Rett eller?
      return new Style({
        image: new Icon({
          src: toalett,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 70:
      return new Style({
        image: new Icon({
          src: utedo,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 71:
      return new Style({
        image: new Icon({
          src: ankring,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 277:
      return new Style({
        image: new Icon({
          src: padling,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    // Offentlig transport

    case 281:
      return new Style({
        image: new Icon({
          src: sykkeltur,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 282:
      return new Style({
        image: new Icon({
          src: fottur,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 111:
      return new Style({
        image: new Icon({
          src: hytte,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    case 555:
    case 444:
      return new Style({
        image: new Icon({
          src: hyttemer,
          anchor: [0.5, 1],
          opacity,
        }),
        zIndex,
      });

    default:
      return new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({ color: "#003b5c" }),
          stroke: new Stroke({ color: "#fff", width: 2 }),
          anchor: [0.5, 1],
        }),
        zIndex,
      });
  }
};

export default featureStyle;
